import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "panel-address"
}
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-col-12 p-md-6 p-lg-6" }
const _hoisted_4 = { class: "p-col-12 p-md-6 p-lg-6" }
const _hoisted_5 = { class: "p-col-12 p-md-6 p-lg-6" }
const _hoisted_6 = { class: "p-col-12 p-md-6 p-lg-6" }
const _hoisted_7 = {
  key: 0,
  class: "p-col-12 p-md-6 p-lg-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputTextCustom = _resolveComponent("InputTextCustom")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.form)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_InputTextCustom, {
                "model-value": _ctx.form.telephone,
                textFloat: "หมายเลขโทรศัพท์",
                placeholder: "หมายเลขโทรศัพท์",
                inputmode: "numeric",
                type: "number",
                name: "telephone",
                oninput: "if( this.value.length > 10 )  this.value = this.value.slice(0,10)",
                isRequired: "",
                onOnkeypress: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isNumber($event))),
                "onUpdate:modelValue": _ctx.handlerZipcode,
                isValidate: _ctx.v.telephone.$error,
                v: _ctx.v.telephone,
                class: "f-regular"
              }, null, 8, ["model-value", "onUpdate:modelValue", "isValidate", "v"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_InputTextCustom, {
                "model-value": _ctx.form.firstname,
                textFloat: "ชื่อ",
                placeholder: "ชื่อ",
                type: "text",
                name: "nameOrCompanyName",
                isRequired: "",
                isValidate: _ctx.v.firstname.$error,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.firstname = $event)),
                v: _ctx.v.firstname,
                class: "f-regular"
              }, null, 8, ["model-value", "isValidate", "v"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_InputTextCustom, {
                "model-value": _ctx.form.lastname,
                textFloat: "นามสกุล",
                placeholder: "นามสกุล",
                type: "text",
                name: "lastname",
                isRequired: "",
                isValidate: _ctx.v.lastname.$error,
                v: _ctx.v.lastname,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.lastname = $event)),
                class: "f-regular"
              }, null, 8, ["model-value", "isValidate", "v"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_InputTextCustom, {
                type: "text",
                textFloat: "อีเมล์",
                placeholder: "อีเมล์",
                "model-value": _ctx.form.email,
                class: "f-regular",
                isValidate: _ctx.v.email.$error,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.form.email = $event)),
                v: _ctx.v.email
              }, null, 8, ["model-value", "isValidate", "v"])
            ]),
            (_ctx.useTheoneCard)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_InputTextCustom, {
                    type: "text",
                    textFloat: "The 1 Card (หมายเลขบัตร, หมายเลขโทรศัพท์)",
                    placeholder: "The 1 Card (หมายเลขบัตร, หมายเลขโทรศัพท์)",
                    "model-value": _ctx.form.theOneCardRef,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.form.theOneCardRef = $event)),
                    class: "f-regular"
                  }, null, 8, ["model-value"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}