
import { SlipDetail } from "@/types/transaction/TransactionPayment.interface";
import { defineComponent, ref, PropType } from "vue";

export default defineComponent({
  props: {
    transferSlip: {
      required: true,
      type: Object as PropType<SlipDetail>,
    },
    imageList: {
      required: true,
      type: Array,
    },
    fileNameLists: {
      required: true,
      type: Array,
    },
    uploadSlipMessage: {
      required: true,
      type: String,
    },
    imageDisplayList: {
      required: true,
      type: Array,
    },
  },
  emits: [
    "onHandleIsSelectedPaymentMethod",
    "onFileChange",
    "setImageIndex",
    "onDeleteImageItem",
  ],
  setup(props, { emit }) {
    const isCollapsed = ref<boolean>(false);

    const onDeleteImageItem = (index: number) => {
      emit("onDeleteImageItem", index);
    };

    const setImageIndex = (index: number) => {
      emit("setImageIndex", index);
    };

    const onFileChange = (
      e: UIEvent & { target: HTMLInputElement & { files: Array<string> } }
    ) => {
      emit("onFileChange", e);
    };

    const onHandleIsSelectedPaymentMethod = () => {
      emit("onHandleIsSelectedPaymentMethod");
    };

    return {
      isCollapsed,
      onFileChange,
      onHandleIsSelectedPaymentMethod,
      setImageIndex,
      onDeleteImageItem,
    };
  },
});
