
import { UserInformation } from "@/types/transaction/TransactionAddress.interface";
import { ValidationArgs } from "@vuelidate/core";
import { computed, defineComponent, PropType, ref } from "vue";
import InputTextCustom from "@/components/inputs/InputText.vue";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    InputTextCustom,
  },
  props: {
    v: {
      required: true,
      type: Object as PropType<ValidationArgs>,
    },
  },
  setup(props) {
    const form = ref<UserInformation>();
    const store = useStore();

    const isNumber = (evt: any) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    };
    const getUserInfoByTelephone = () => {
      console.log("getUserInfoByTelephone");
    };

    form.value = computed(
      () => store.getters.getTransaction.buyerInfo.userInfo
    ).value;

    const handlerZipcode = (event: any) => {
      if (!form.value) return;
      form.value.telephone = event.replace(/[^0-9]+/g, "");
    };

    return {
      useTheoneCard: computed(() => store.getters.getBrand.useThe1Card),
      isNumber,
      getUserInfoByTelephone,
      form,
      handlerZipcode
    };
  },
});
